import React, { useState } from "react"
import styled from "styled-components"
import { Fade, Bounce } from "react-awesome-reveal"

import StudycafeCircle from "../../static/space/studycafe-circle.png"
import Cafe_Firenze from "./slide/space-studycafe-firenze"
import Cafe_Study from "./slide/space-studycafe-study"
import Cafe_Office from "./slide/space-studycafe-office"
import Seminar from "./slide/space-seminar"
import Cafe from "./slide/space-cafe"

const Container = styled.div`
  .space-first-part img {
    width: 100vw;
  }

  .space1-title-text-part {
    width: 60vw;
    position: absolute;
    left: 50vw;
    margin-left: -30vw;
  }

  .space1-title-text-part p {
    font-size: 1.3vw;
    line-height: 1.5;
  }

  .space1-title-text-part p:first-child {
    font-size: 2.3vw;
    margin: 5vw 0 2vw;
  }

  .space1-title-text-part p span {
    font-weight: bold;
  }

  .choice-space {
    width: 60vw;
    margin: 0 auto;
    display: flex;
  }

  .space-study {
    width: 30vw;
    text-align: center;
    border: solid 1px #707070;
    padding: 1.5vw 0;
    font-size: 1.3vw;
    font-weight: bold;
    cursor: pointer;
  }

  .show-this-space {
    color: #d96e62;
    border-bottom: 2px solid #d96e62;
  }

  .study-cafe-title {
    display: flex;
    width: 60vw;
    margin: 3vw auto 4vw;
    justify-content: space-between;
    align-items: center;
  }

  .study-cafe-title img {
    width: 12vw;
    margin-left: 8vw;
  }

  .study-cafe-title-text {
    margin-right: 5vw;
  }

  .study-cafe-title-text p {
    font-size: 1.2vw;
    line-height: 1.5;
  }

  .study-cafe-title-text p:first-child {
    font-size: 1.7vw;
    margin: 1.5vw 0;
  }

  .study-cafe-title-text p span {
    font-weight: bold;
  }

  .choice-spacezone-btn-all {
    display: flex;
    width: 60vw;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }

  .spacezone-btn {
    width: 11.7vw;
    border: none;
    padding: 1vw 0 0.7vw;
    border-radius: 100px;
    background-color: #fff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    font-size: 1.2vw;
    font-weigth: 500;
  }

  .choice-spacezone {
    background-color: #d96e62;
    color: #fff;
    font-weigth: bold;
    box-shadow: none;
  }

  .slick-next,
  .slick-prev,
  .slick-next:before,
  .slick-prev:before {
    display: none;
  }

  div.hide-480 {
    display: flex;
  }

  .show-480 {
    display: none;
  }

  @media screen and (max-width: 480px) {
    margin-top: 4vw;
    .hide-480 {
      display: none !important;
    }

    .show-480 {
      display: block;
    }

    .choice-spacezone-btn-all {
      width: 80vw;
      flex-flow: row wrap;
      justify-content: center;
    }

    .spacezone-btn {
      width: 26.5vw;
      font-size: 3.5vw;
      padding: 1.5vw 0 1.2vw;
      margin-bottom: 4vw;
      font-weight: bold;
    }
  }
`
if (typeof window !== "undefined") {
  var spacezone = document.getElementsByClassName("spacezone-btn")
}
// let btnVelue: number = 5

const space_studycafe = () => {
  const [btnVelue, setBtnValue] = useState("")

  const handleClick = (event: any) => {
    setBtnValue(event.target.value)

    for (var i = 0; i < spacezone.length; i++) {
      spacezone[i].classList.remove("choice-spacezone")
    }

    event.target.classList.add("choice-spacezone")
  }

  return (
    <Container>
      <div className="study-cafe-part">
        <div className="study-cafe-title hide-480">
          <img src={StudycafeCircle} alt="스터디카페 타이틀" />
          <div className="study-cafe-title-text">
            <p>
              공간샘 <span>스터디카페</span>
            </p>
            <p>
              메디치가문이 여러 분야의 학자들을 모아 창조적, 혁신적
              <br />
              아이디어를 창출해내고 르네상스 시대를 열었던 것 처럼
              <br />
              <span>
                공간샘 또한 메디치가문의 기업 경영 방식을 이어 받아
                <br />
                창조적인 공간을 만들고자 다양한 공간을 만들었습니다.
              </span>
            </p>
          </div>
        </div>
        <div className="choice-spacezone-btn-all">
          <button
            className="spacezone-btn choice-spacezone"
            onClick={handleClick}
            value="1"
          >
            피렌체존
          </button>
          <button className="spacezone-btn" onClick={handleClick} value="2">
            스터디존
          </button>
          <button className="spacezone-btn" onClick={handleClick} value="3">
            오피스존
          </button>
          <button className="spacezone-btn" onClick={handleClick} value="4">
            세미나실
          </button>
          <button className="spacezone-btn" onClick={handleClick} value="5">
            카페공간
          </button>
        </div>
        {btnVelue === "2" ? (
          <Cafe_Study />
        ) : btnVelue === "3" ? (
          <Cafe_Office />
        ) : btnVelue === "4" ? (
          <Seminar />
        ) : btnVelue === "5" ? (
          <Cafe />
        ) : (
          <Cafe_Firenze />
        )}
      </div>
    </Container>
  )
}

export default space_studycafe
