import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Slider from "react-slick"

import Office_loungeZone1 from "../../../static/space/studyoffice/studyoffice-lounge1.jpg"
import Office_loungeZone2 from "../../../static/space/studyoffice/studyoffice-lounge2.jpg"
import Office_loungeZone3 from "../../../static/space/studyoffice/studyoffice-lounge3.jpg"
import Office_loungeZone4 from "../../../static/space/studyoffice/studyoffice-lounge4.jpg"
import Office_loungeZone5 from "../../../static/space/studyoffice/studyoffice-lounge5.jpg"

const Container = styled.div`
  padding: 1vw 0 7vw;

  .title-slide {
    margin: 3vw 0 2vw;
  }
  .office-loungezone-slide-first img {
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
  }
  .office-loungezone-slide-first {
    height: 30vw;
  }

  .office-loungezone-slide-second {
    height: 7.5vw;
  }

  .office-loungezone-slide-second img {
    width: 14.5vw;
    height: 7.5vw;
    margin: 0 auto;
  }

  .slick-slider {
    width: 60.4vw;
    margin: 3vw auto;
  }
  .slick-next,
  .slick-prev,
  .slick-next:before,
  .slick-prev:before {
    display: none;
  }

  .hide-480 {
    display: block;
  }

  .show-480 {
    display: none;
  }

  @media screen and (max-width: 480px) {
    .hide-480 {
      display: none;
    }

    .show-480 {
      display: block;
    }

    img.slide-img-480 {
      width: 80vw;
      margin: 0 auto 2.5vw;
      display: block;
    }
  }
`

const studyoffice_lounge = () => {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  let slider1: any = []
  let slider2: any = []

  useEffect(() => {
    setNav1(slider1)
    setNav2(slider2)
  }, [slider1, slider2])

  return (
    <Container>
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <div className="show-480">
        <img
          className="slide-img-480"
          src={Office_loungeZone1}
          alt="오피스 라운지1"
        />
        <img
          className="slide-img-480"
          src={Office_loungeZone2}
          alt="오피스 라운지2"
        />
        <img
          className="slide-img-480"
          src={Office_loungeZone3}
          alt="오피스 라운지3"
        />
        <img
          className="slide-img-480"
          src={Office_loungeZone4}
          alt="오피스 라운지4"
        />
        <img
          className="slide-img-480"
          src={Office_loungeZone5}
          alt="오피스 라운지5"
        />
      </div>
      <div>
        <Slider
          className="title-slide hide-480"
          asNavFor={nav2}
          ref={slider => (slider1 = slider)}
        >
          <div className="office-loungezone-slide-first">
            <img src={Office_loungeZone1} alt="오피스 라운지1" />
          </div>
          <div className="office-loungezone-slide-first">
            <img src={Office_loungeZone2} alt="오피스 라운지2" />
          </div>
          <div className="office-loungezone-slide-first">
            <img src={Office_loungeZone3} alt="오피스 라운지3" />
          </div>
          <div className="office-loungezone-slide-first">
            <img src={Office_loungeZone4} alt="오피스 라운지4" />
          </div>
          <div className="office-loungezone-slide-first">
            <img src={Office_loungeZone5} alt="오피스 라운지5" />
          </div>
        </Slider>
        <Slider
          className="same-title-slide hide-480"
          asNavFor={nav1}
          ref={slider => (slider2 = slider)}
          slidesToShow={4}
          swipeToSlide={true}
          focusOnSelect={true}
        >
          <div className="office-loungezone-slide-second">
            <img src={Office_loungeZone1} alt="오피스 라운지1" />
          </div>
          <div className="office-loungezone-slide-second">
            <img src={Office_loungeZone2} alt="오피스 라운지2" />
          </div>
          <div className="office-loungezone-slide-second">
            <img src={Office_loungeZone3} alt="오피스 라운지3" />
          </div>
          <div className="office-loungezone-slide-second">
            <img src={Office_loungeZone4} alt="오피스 라운지4" />
          </div>
          <div className="office-loungezone-slide-second">
            <img src={Office_loungeZone5} alt="오피스 라운지5" />
          </div>
        </Slider>
      </div>
    </Container>
  )
}

export default studyoffice_lounge
