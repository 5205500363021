import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Slider from "react-slick"

import Common_Cafe1 from "../../../static/space/studycommon/study-cafe1.jpg"
import Common_Cafe2 from "../../../static/space/studycommon/study-cafe2.jpg"
import Common_Cafe3 from "../../../static/space/studycommon/study-cafe3.jpg"
import Common_Cafe4 from "../../../static/space/studycommon/study-cafe4.jpg"

const Container = styled.div`
  padding: 1vw 0 7vw;

  .title-slide {
    margin: 3vw 0 2vw;
  }
  .cafe-slide-first img {
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
  }
  .cafe-slide-first {
    height: 30vw;
  }

  .cafe-slide-second {
    height: 7.5vw;
  }

  .cafe-slide-second img {
    width: 14.5vw;
    margin: 0 auto;
  }

  .slick-slider {
    width: 60.4vw;
    margin: 3vw auto;
  }
  .slick-next,
  .slick-prev,
  .slick-next:before,
  .slick-prev:before {
    display: none;
  }

  .hide-480 {
    display: block;
  }

  .show-480 {
    display: none;
  }

  @media screen and (max-width: 480px) {
    .hide-480 {
      display: none;
    }

    .show-480 {
      display: block;
    }

    img.slide-img-480 {
      width: 80vw;
      margin: 0 auto 2.5vw;
      display: block;
    }
  }
`

const space_cafe = () => {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  let slider1: any = []
  let slider2: any = []

  useEffect(() => {
    setNav1(slider1)
    setNav2(slider2)
  }, [slider1, slider2])

  return (
    <Container>
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />

      <div className="show-480">
        <img className="slide-img-480" src={Common_Cafe1} alt="카페공간1" />
        <img className="slide-img-480" src={Common_Cafe2} alt="카페공간2" />
        <img className="slide-img-480" src={Common_Cafe3} alt="카페공간3" />
        <img className="slide-img-480" src={Common_Cafe4} alt="카페공간4" />
      </div>
      <div>
        <Slider
          className="title-slide hide-480"
          asNavFor={nav2}
          ref={slider => (slider1 = slider)}
        >
          <div className="cafe-slide-first">
            <img src={Common_Cafe1} alt="카페공간1" />
          </div>
          <div className="cafe-slide-first">
            <img src={Common_Cafe2} alt="카페공간2" />
          </div>
          <div className="cafe-slide-first">
            <img src={Common_Cafe3} alt="카페공간3" />
          </div>
          <div className="cafe-slide-first">
            <img src={Common_Cafe4} alt="카페공간4" />
          </div>
          <div className="cafe-slide-first">
            <img src={Common_Cafe1} alt="카페공간1" />
          </div>
          <div className="cafe-slide-first">
            <img src={Common_Cafe2} alt="카페공간2" />
          </div>
          <div className="cafe-slide-first">
            <img src={Common_Cafe3} alt="카페공간3" />
          </div>
          <div className="cafe-slide-first">
            <img src={Common_Cafe4} alt="카페공간4" />
          </div>
        </Slider>
        <Slider
          className="same-title-slide hide-480"
          asNavFor={nav1}
          ref={slider => (slider2 = slider)}
          slidesToShow={4}
          swipeToSlide={true}
          focusOnSelect={true}
        >
          <div className="cafe-slide-second">
            <img src={Common_Cafe1} alt="카페공간1" />
          </div>
          <div className="cafe-slide-second">
            <img src={Common_Cafe2} alt="카페공간2" />
          </div>
          <div className="cafe-slide-second">
            <img src={Common_Cafe3} alt="카페공간3" />
          </div>
          <div className="cafe-slide-second">
            <img src={Common_Cafe4} alt="카페공간4" />
          </div>
          <div className="cafe-slide-second">
            <img src={Common_Cafe1} alt="카페공간1" />
          </div>
          <div className="cafe-slide-second">
            <img src={Common_Cafe2} alt="카페공간2" />
          </div>
          <div className="cafe-slide-second">
            <img src={Common_Cafe3} alt="카페공간3" />
          </div>
          <div className="cafe-slide-second">
            <img src={Common_Cafe4} alt="카페공간4" />
          </div>
        </Slider>
      </div>
    </Container>
  )
}

export default space_cafe
