import React, { useState } from "react"
import styled from "styled-components"
import { Fade, Bounce } from "react-awesome-reveal"

import StudyofficeCircle from "../../static/space/studyoffice-circle.jpeg"
import Office_office from "./slide/space-studyoffice-office"
import Office_lounge from "./slide/space-studyoffice-lounge"
import Office_study from "./slide/space-studyoffice-study"
import Seminar from "./slide/space-seminar"
import Cafe from "./slide/space-cafe"

const Container = styled.div`
  .space-first-part img {
    width: 100vw;
  }

  .space1-title-text-part {
    width: 60vw;
    position: absolute;
    left: 50vw;
    margin-left: -30vw;
  }

  .space1-title-text-part p {
    font-size: 1.3vw;
    line-height: 1.5;
  }

  .space1-title-text-part p:first-child {
    font-size: 2.3vw;
    margin: 5vw 0 2vw;
  }

  .space1-title-text-part p span {
    font-weight: bold;
  }

  .choice-space {
    width: 60vw;
    margin: 0 auto;
    display: flex;
  }

  .space-study {
    width: 30vw;
    text-align: center;
    border: solid 1px #707070;
    padding: 1.5vw 0;
    font-size: 1.3vw;
    font-weight: bold;
    cursor: pointer;
  }

  .show-this-space {
    color: #d96e62;
    border-bottom: 2px solid #d96e62;
  }

  .study-office-title {
    display: flex;
    width: 60vw;
    margin: 3vw auto 4vw;
    justify-content: space-between;
    align-items: center;
  }

  .study-office-img img {
    height: 12vw;
    width: 12vw;
    border-radius: 50%;
    margin-left: 8vw;
  }

  .study-office-title-text {
    margin-right: 2.3vw;
  }

  .study-office-title-text p {
    font-size: 1.2vw;
    line-height: 1.5;
  }

  .study-office-title-text p:first-child {
    font-size: 1.7vw;
    margin: 1.5vw 0;
  }

  .study-office-title-text p span {
    font-weight: bold;
  }

  .choice-spacezone-btn-all {
    display: flex;
    width: 60vw;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }

  .spacezone-btn {
    width: 11.7vw;
    border: none;
    padding: 1vw 0 0.7vw;
    border-radius: 100px;
    background-color: #fff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    font-size: 1.2vw;
    font-weigth: 500;
  }

  .choice-spacezone {
    background-color: #d96e62;
    color: #fff;
    font-weigth: bold;
    box-shadow: none;
  }

  .slick-next,
  .slick-prev,
  .slick-next:before,
  .slick-prev:before {
    display: none;
  }
  div.hide-480 {
    display: flex;
  }

  .show-480 {
    display: none;
  }

  @media screen and (max-width: 480px) {
    margin-top: 4vw;
    .hide-480 {
      display: none !important;
    }

    .show-480 {
      display: block;
    }

    .choice-spacezone-btn-all {
      width: 80vw;
      flex-flow: row wrap;
      justify-content: center;
    }

    .spacezone-btn {
      width: 26.5vw;
      font-size: 3.5vw;
      padding: 1.5vw 0 1.2vw;
      margin-bottom: 4vw;
      font-weight: bold;
    }
  }
`
if (typeof window !== "undefined") {
  var spacezone = document.getElementsByClassName("spacezone-btn")
}
// let btnVelue: number = 5

const space_studyoffice = () => {
  const [btnValue, setBtnValue] = useState("")

  const handleClick = (event: any) => {
    setBtnValue(event.target.value)

    for (var i = 0; i < spacezone.length; i++) {
      spacezone[i].classList.remove("choice-spacezone")
    }

    event.target.classList.add("choice-spacezone")
  }

  return (
    <Container>
      <div className="study-office-part">
        <div className="study-office-title hide-480">
          <div className="study-office-img">
            <img src={StudyofficeCircle} alt="스터디오피스 타이틀" />
          </div>
          <div className="study-office-title-text">
            <p>
              공간샘 <span>스터디오피스</span>
            </p>
            <p>
              <span>재택근무자, 프리랜서에게도 최소한의 업무공간</span>이
              필요합니다.
              <br />
              업무에 적합한 오피스존 &amp; 라운지 퇴근후 자기계발에 <br />
              적합한 스터디존까지 <span>다양한 학습 환경</span>을 제공합니다.
            </p>
          </div>
        </div>
        <div className="choice-spacezone-btn-all">
          <button
            className="spacezone-btn choice-spacezone"
            onClick={handleClick}
            value="1"
          >
            오피스존
          </button>
          <button className="spacezone-btn" onClick={handleClick} value="2">
            라운지
          </button>
          <button className="spacezone-btn" onClick={handleClick} value="3">
            스터디존
          </button>
          <button className="spacezone-btn" onClick={handleClick} value="4">
            세미나실
          </button>
          <button className="spacezone-btn" onClick={handleClick} value="5">
            카페공간
          </button>
        </div>
        {btnValue === "2" ? (
          <Office_lounge />
        ) : btnValue === "3" ? (
          <Office_study />
        ) : btnValue === "4" ? (
          <Seminar />
        ) : btnValue === "5" ? (
          <Cafe />
        ) : (
          <Office_office />
        )}
      </div>
    </Container>
  )
}

export default space_studyoffice
