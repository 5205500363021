import React, { useState } from "react"
import styled from "styled-components"

import Space1 from "../../static/space/space1.png"
import Space1_480 from "../../static/space/space1-480.png"
import Studycafe from "./space-studycafe"
import Studyoffice from "./space-studyoffice"
const Container = styled.div`
  .space-first-part img {
    width: 100vw;
  }

  .space1-title-text-part {
    width: 60vw;
    position: absolute;
    left: 50vw;
    margin-left: -30vw;
  }

  .space1-title-text-part p {
    font-size: 1.3vw;
    line-height: 1.5;
  }

  .space1-title-text-part p:first-child {
    font-size: 2.3vw;
    margin: 5vw 0 2vw;
  }

  .space1-title-text-part p span {
    font-weight: bold;
  }

  .choice-space {
    width: 60vw;
    margin: -2.5vw auto 0;
    display: flex;
  }

  .space-study {
    width: 30vw;
    text-align: center;
    border: solid 1px #b0b0b0;
    padding: 1.5vw 0;
    font-size: 1.3vw;
    font-weight: bold;
    cursor: pointer;
    background-color: #fff;
    color: #b0b0b0;
  }

  .show-this-space {
    color: #d96e62;
    border-bottom: 2px solid #d96e62;
  }

  .hide-480 {
    display: block;
  }

  .show-480 {
    display: none;
  }

  @media screen and (max-width: 480px) {
    .hide-480 {
      display: none;
    }

    .show-480 {
      display: block;
    }

    .space1-title-text-part {
      width: 80vw;
      margin-left: -40vw;
    }

    .space1-title-text-part p:first-child {
      font-size: 7vw;
    }
    .space1-title-text-part p {
      font-size: 4vw;
    }

    .choice-space {
      width: 100vw;
      justify-content: space-between;
      margin: 0;
    }

    .space-study {
      width: 49vw;
      border: none;
      border-bottom: solid 1px #707070;
      font-size: 4.2vw;
      color: #212121;
      padding: 3.5vw 0;
    }

    .show-this-space {
      color: #d96e62;
      border-bottom: 2px solid #d96e62;
    }
  }
`

if (typeof window !== "undefined") {
  var choice_space = document.getElementsByClassName("space-study")
}

const space1 = () => {
  const [spaceValue, setSpaceValue] = useState("")

  const handleClick = (event: any) => {
    setSpaceValue(event.target.value)

    for (var i = 0; i < choice_space.length; i++) {
      choice_space[i].classList.remove("show-this-space")
    }

    event.target.classList.add("show-this-space")
  }

  return (
    <Container>
      <div className="space1-title-text-part">
        <p>
          공간샘 <span>공간소개</span>
        </p>
        <p>
          학습자마다 학습 패턴이 다르기에{" "}
          <span>
            학습공간은 <br className="show-480" />
            다양해야 합니다
          </span>
          <br className="hide-480" />
          다양한 학습자들을 고려해
          <br />
          <span>특허받은 방음기술과 친환경 인테리어</span>를 적용한
          <br />
          <span>공간샘 프리미엄 학습 공간</span>입니다.
        </p>
      </div>
      <div className="space-first-part">
        <img className="hide-480" src={Space1} alt="space 첫번째 이미지" />
        <img className="show-480" src={Space1_480} alt="space 첫번째 이미지" />
      </div>
      <div className="choice-space">
        <button
          className="space-study-cafe space-study show-this-space"
          onClick={handleClick}
          value="study"
        >
          스터디카페
        </button>
        <button
          className="space-study-office space-study"
          onClick={handleClick}
          value="office"
        >
          스터디오피스
        </button>
      </div>
      {spaceValue == "office" ? <Studyoffice /> : <Studycafe />}
    </Container>
  )
}

export default space1
